export const drinksData = {
    drinks: [
        {
            category: "Pivo",
            items: [
                { name: "Plzeň", price: "0.3l/0.5l 42,-/56,-" },
                { name: "Radegast Ratar", price: "0.3l/0.5l 34,-/44,-" },
                { name: "Birell Pomelo/Grep", price: "0.3l/0.5l 34,-/44,-" },
                { name: "Birell lahev 0.33", price: "35,-" }
            ]
        },
        {
            category: "Nealko",
            items: [
                { name: "Coca cola", price: "45,-" },
                { name: "Fanta", price: "45,-" },
                { name: "Sprite", price: "45,-" },
                { name: "Tonic", price: "45,-" },
                { name: "Ochucený tonic", price: "45,-" },
                { name: "Voda jemně perlivá", price: "35,-" },
                { name: "Voda neperlivá", price: "35,-" },
                { name: "Redbull", price: "80,-" },
                { name: "Monster", price: "60,-" },
                { name: "Domácí limonáda", price: "0.3l 35,-/0.5l 65,-/1l 120,-" },
                { name: "Soda", price: "0.1l 8,-" },
                { name: "Džus/dle nabídky", price: "45,-" }
            ]
        },
        {
            category: "Teplé nápoje",
            items: [
                { name: "Espresso", price: "55,-" },
                { name: "Čaj/dle nabídky", price: "45,-" }
            ]
        },
        {
            category: "Víno",
            items: [
                { name: "Bílé /dle nabídky", price: "0.75l/0.2l 180,-/65,-" },
                { name: "Červené dle nabídky", price: "0.75l/0.2l 180,-/65,-" },
                { name: "Prosecco", price: "0.75l/0.2l 220,-/75,-" }
            ]
        },
        {
            category: "Rum",
            items: [
                { name: "Božkov rum", price: "0.04l 42,-" },
                { name: "Božkov káva", price: "0.04l 42,-" },
                { name: "Captain Morgan Spiced", price: "0.04l 55,-" },
                { name: "Havana club", price: "0.04l 60,-" },
                { name: "Božkov Republica", price: "0.04l 55,-" }
            ]
        },
        {
            category: "Whiskey",
            items: [
                { name: "Jack Daniels", price: "0.04l 80,-" },
                { name: "Jameson", price: "0.04l 65,-" },
                { name: "Tullamore", price: "0.04l 65,-" }
            ]
        },
        {
            category: "Ostatní",
            items: [
                { name: "Beefeater", price: "0.04l 60,-" },
                { name: "Becherovka", price: "0.04l 45,-" },
                { name: "Božkov zelená", price: "0.04l 35,-" },
                { name: "Fernet", price: "0.04l 45,-" },
                { name: "Jagermaister", price: "0.04l 60,-" },
                { name: "Stará myslivecká", price: "0.04l 50,-" },
                { name: "Finlandia", price: "0.04l 50,-" },
                { name: "Tatranský čaj", price: "0.04l 75,-" }
            ]
        },
        {
            category: "Pochutiny",
            items: [
                { name: "Arašídy", price: "35,-" },
                { name: "Chipsy", price: "45,-" }
            ]
        }
    ]
};
