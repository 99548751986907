import { ref, onValue } from "firebase/database";
import {database} from "../../firebaseConfig";
import {MenuProps} from "../menu/type";

export const fetchMenuData = (path: string, callback: (data: MenuProps) => void) => {
    const menuRef = ref(database, path);

    onValue(menuRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
            callback(data);
        }
    });
};
