import { ref, set } from "firebase/database";
import {MenuProps} from "../menu/type";
import {database} from "../../firebaseConfig";


export const handleSave = async (data: MenuProps) => {
    try {
        const menuRef = ref(database, 'menuData');
        await set(menuRef, data);
        console.log("Uložená data", data);
        alert('Data byla úspěšně uložena do Firebase!');
    } catch (error) {
        console.error("Chyba při ukládání dat: ", error);
        alert('Došlo k chybě při ukládání dat. Zkuste to prosím znovu.');
    }
};
