import React, {useEffect, useState} from 'react';
import {Nav, Navbar} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import './Header.css';
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "../../firebaseConfig";
import LogoutButton from "../LogoutButton";

const Header: React.FC = () => {
    const [user, setUser] = useState<any>(null);

    useEffect(() => {
        const handleScroll = () => {
            const navbarCollapse = document.querySelector('.navbar-collapse');
            if (navbarCollapse && navbarCollapse.classList.contains('show')) {
                navbarCollapse.classList.remove('show');
            }
        };

        window.addEventListener('scroll', handleScroll);

        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });

        return () => {
            window.removeEventListener('scroll', handleScroll);
            unsubscribe();
        };
    }, []);

    return (
        <Navbar expand="lg" fixed="top" className="custom-navbar">
            <LinkContainer to="/">
                <Navbar.Brand className="brand-padding">Jirčanská Hospůdka</Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggler"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto custom-nav">
                    <LinkContainer to="/">
                        <Nav.Link>Domů</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/menu">
                        <Nav.Link>Jídelní lístek</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/drinks-menu">
                        <Nav.Link>Nápojový lístek</Nav.Link>
                    </LinkContainer>
                    {user && (
                        <>
                            <LinkContainer to="/admin/editable-menu">
                                <Nav.Link>Upravit menu</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to={"/admin/editable-action"}>
                                <Nav.Link>Upravit akce</Nav.Link>
                            </LinkContainer>
                            <LogoutButton/>
                        </>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;
