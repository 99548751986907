import React from 'react';
import './About.css';
import OpeningHours from "../OpeningHours";
import {Col, Row} from 'react-bootstrap';
import GoogleMap from "./googleMaps/GoogleMap";
import ActionInfoField from "../actionInfo/ActionInfoField";

const About: React.FC = () => {
    return (
        <div className="about">
            <ActionInfoField />
            <Row>
                <Col lg={6} md={12} className="mb-3">
                    <img
                        src="https://lh5.googleusercontent.com/p/AF1QipPCLyBnRv9Hpg6wB0hs0SqXr6p65lSRYv6Fpt4V=s483-k-no"
                        alt="Hospůdka"
                        className="img-fluid"
                    />
                </Col>
                <Col lg={6} md={12}>
                    <GoogleMap/>
                </Col>
            </Row>
            <p>Naše nová hospůdka je ideálním místem pro všechny, kdo hledají skvělou atmosféru, chutné jídlo a příjemné
                ceny. Přijďte si pochutnat na našem denním menu během pracovního dne nebo si večer vychutnejte naše
                speciality, jako jsou lahodné burgery a grilované maso.</p>
            <OpeningHours/>
        </div>
    );
};

export default About;
