import React from "react";
import {MenuItemRowProps} from "./type";
import {useDrag, useDrop} from "react-dnd";
import {Button, Form, ListGroup} from "react-bootstrap";

const MenuItemRow: React.FC<MenuItemRowProps> = ({item, categoryIndex, itemIndex, moveItem, onChange, onDelete}) => {
    const ref = React.useRef<HTMLDivElement>(null);

    const [, drop] = useDrop({
        accept: 'MenuItem',
        hover: (draggedItem: any) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = draggedItem.itemIndex;
            const hoverIndex = itemIndex;
            if (dragIndex === hoverIndex) {
                return;
            }
            moveItem(categoryIndex, dragIndex, hoverIndex);
            draggedItem.itemIndex = hoverIndex;
        },
    });

    const [{isDragging}, drag] = useDrag({
        type: 'MenuItem',
        item: {categoryIndex, itemIndex},
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <ListGroup.Item ref={ref as any} as="li" className="d-flex flex-column align-items-start p-0 border-0"
            style={{ opacity: isDragging ? 0.5 : 1 }}>
            <Form.Group className="w-100 m-1">
                <Form.Control
                    type="text"
                    value={item.name}
                    as={"textarea"}
                    rows={window.innerWidth > 720 ? 1 : 3}
                    onChange={(e) => onChange('name', e.target.value)}
                    placeholder="Název položky"
                />
            </Form.Group>
            <Form.Group className="w-100 m-1">
                <Form.Control
                    type="text"
                    value={item.price}
                    onChange={(e) => onChange('price', e.target.value)}
                    placeholder="Cena"
                />
            </Form.Group>
            <Button variant="danger" onClick={onDelete} className={"m-1"}>Smazat</Button>
        </ListGroup.Item>
    );
};

export default MenuItemRow;