import { Accordion, Button, ListGroup } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { mapCategoriesToMenuData, mapMenuDataToCategories } from "./mapMenuDataToCategories";
import { ref, get, set } from "firebase/database";
import {EditableMenuProps, MenuCategory} from "./type";
import {database} from "../../firebaseConfig";
import {MenuProps} from "../menu/type";
import MenuItemRow from "./MenuItem";

const EditableMenu: React.FC<EditableMenuProps> = ({ data, onSave }) => {
    const [menuData, setMenuData] = useState<MenuCategory[]>(mapMenuDataToCategories(data));
    useEffect(() => {
        const fetchData = async () => {
            const dbRef = ref(database, 'menuData');
            const snapshot = await get(dbRef);
            if (snapshot.exists()) {
                setMenuData(mapMenuDataToCategories(snapshot.val()));
            }
        };

        fetchData();
    }, []);

    const moveItem = (categoryIndex: keyof MenuProps, dragIndex: number, hoverIndex: number) => {
        const updatedCategory = [...menuData];
        const category = updatedCategory.find(cat => cat.categoryName === categoryIndex);
        if (category) {
            const [draggedItem] = category.items.splice(dragIndex, 1);
            category.items.splice(hoverIndex, 0, draggedItem);
            setMenuData(updatedCategory);
        }
    };

    const handleSave = async () => {
        const formattedData = mapCategoriesToMenuData(menuData);
        await set(ref(database, 'menuData'), formattedData);
        onSave(formattedData);
    };

    const handleAddItem = (categoryIndex: keyof MenuProps) => {
        const updatedCategory = [...menuData];
        const category = updatedCategory.find(cat => cat.categoryName === categoryIndex);
        if (category) {
            category.items.push({
                id: Date.now().toString(),
                name: 'Nová položka',
                price: '0,-',
            });
            setMenuData(updatedCategory);
        }
    };

    const handleDeleteItem = (categoryIndex: keyof MenuProps, itemIndex: number) => {
        const updatedCategory = [...menuData];
        const category = updatedCategory.find(cat => cat.categoryName === categoryIndex);
        if (category) {
            category.items.splice(itemIndex, 1);
            setMenuData(updatedCategory);
        }
    };

    const handleItemChange = (categoryIndex: keyof MenuProps, itemIndex: number, key: 'name' | 'price', value: string) => {
        const updatedCategory = [...menuData];
        const category = updatedCategory.find(cat => cat.categoryName === categoryIndex);
        if (category) {
            category.items[itemIndex][key] = value;
            setMenuData(updatedCategory);
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Accordion>
                {menuData.map((category, categoryIndex) => (
                    <Accordion.Item eventKey={String(categoryIndex)} key={categoryIndex}>
                        <Accordion.Header>{category.categoryName}</Accordion.Header>
                        <Accordion.Body>
                            <ListGroup>
                                {category.items.map((item, itemIndex) => (
                                    <MenuItemRow
                                        key={item.id}
                                        item={item}
                                        categoryIndex={category.categoryName}
                                        itemIndex={itemIndex}
                                        moveItem={moveItem}
                                        onChange={(key, value) => handleItemChange(category.categoryName, itemIndex, key, value)}
                                        onDelete={() => handleDeleteItem(category.categoryName, itemIndex)}
                                    />
                                ))}
                            </ListGroup>
                            <Button variant="success" onClick={() => handleAddItem(category.categoryName)} className="mt-3">
                                Přidat položku
                            </Button>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
            <Button variant="primary" onClick={handleSave} className="mt-4">
                Uložit změny
            </Button>
        </DndProvider>
    );
};

export default EditableMenu;
