import React from 'react';
import './DrinksMenu.css';
import {DrinksMenuProps} from "./type";

const DrinksMenu: React.FC<DrinksMenuProps> = ({ data }) => {
    return (
        <section id="drinks-menu">
            <h2>Jirčanská hospůdka Na Návsi</h2>
            {data.drinks.map((drinkCategory, index) => (
                <React.Fragment key={index}>
                    <h3>{drinkCategory.category}</h3>
                    <ul>
                        {drinkCategory.items.map((item, itemIndex) => (
                            <li key={itemIndex}>
                                <b>{item.name}</b>
                                <span className="price">{item.price}</span>
                            </li>
                        ))}
                    </ul>
                </React.Fragment>
            ))}
        </section>
    );
};

export default DrinksMenu;