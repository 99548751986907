import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const OpeningHours: React.FC = () => {
    return (
        <section
            id="opening-hours"
            className="bg-light py-4 mx-md-4 mx-0 my-3 rounded shadow-sm"
            style={{
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            }}
        >
            <h2
                className="text-center mb-4"
                style={{ fontSize: '2.5em' }}
            >
                Otevírací hodiny
            </h2>
            <ul className="list-unstyled text-center m-0" style={{ fontSize: '1.2em' }}>
                <li style={{ margin: '10px 0', padding: '10px 20px', borderBottom: '1px solid #ddd' }}>
                    Pondělí - Zavřeno
                </li>
                <li style={{ margin: '10px 0', padding: '10px 20px', borderBottom: '1px solid #ddd' }}>
                    Úterý - 11:00 - 22:00
                </li>
                <li style={{ margin: '10px 0', padding: '10px 20px', borderBottom: '1px solid #ddd' }}>
                    Středa - 11:00 - 22:00
                </li>
                <li style={{ margin: '10px 0', padding: '10px 20px', borderBottom: '1px solid #ddd' }}>
                    Čtvrtek - 11:00 - 22:00
                </li>
                <li style={{ margin: '10px 0', padding: '10px 20px', borderBottom: '1px solid #ddd' }}>
                    Pátek - 11:00 - 24:00
                </li>
                <li style={{ margin: '10px 0', padding: '10px 20px', borderBottom: '1px solid #ddd' }}>
                    Sobota - 11:00 - 22:00
                </li>
                <li style={{ margin: '10px 0', padding: '10px 20px', borderBottom: '1px solid #ddd' }}>
                    Neděle - 12:00 - 21:00
                </li>
            </ul>
        </section>
    );
};

export default OpeningHours;
