import React, {useEffect, useState} from 'react';
import './App.css';
import Footer from './components/Footer';
import {Route, Routes, useLocation} from 'react-router-dom';
import {Card, Col, Container, Row} from 'react-bootstrap';
import Header from "./components/headerNavBar/Header";
import About from "./components/about/About";
import Menu from "./components/menu/Menu";
import DrinksMenu from "./components/drinks/DrinksMenu";
import {drinksData} from "./components/drinks/drinksData";
import EditableMenuField from "./components/editableMenu/EditableMenuField";
import {onAuthStateChanged} from "firebase/auth";
import {analytics, auth} from "./firebaseConfig";
import {ProtectedRoute} from "./components/ProtectedRoute";
import Login from "./components/Login";
import {logEvent} from "firebase/analytics";
import EditableActionsField from "./components/actionInfo/actionEditor/EditableActionsField";


const App: React.FC = () => {
    const [user, setUser] = useState<any>(null);
    const location = useLocation();

    // Sleduj změny URL pro page views
    useEffect(() => {
        logEvent(analytics, 'page_view', {page_path: location.pathname});
    }, [location]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser: any) => {
            setUser(currentUser);
        });

        return () => unsubscribe();
    }, []);

    // Funkce pro logování událostí kliknutí
    const handleMenuClick = () => {
        logEvent(analytics, 'select_content', {
            content_type: 'menu',
            content_id: 'menu_page'
        });
    };

    const handleDrinksMenuClick = () => {
        logEvent(analytics, 'select_content', {
            content_type: 'drinks',
            content_id: 'drinks_menu_page'
        });
    };

    return (
        <div>
            <Header/>
            <Container className="mt-5 pt-5">
                <Row>
                    <Col>
                        <Routes>
                            <Route path="/" element={
                                <Card className="mb-4">
                                    <Card.Body>
                                        <About/>
                                    </Card.Body>
                                </Card>
                            }/>
                            <Route path="/menu" element={
                                <Card className="mb-4">
                                    <Card.Body onClick={handleMenuClick}>
                                        <Menu/>
                                    </Card.Body>
                                </Card>
                            }/>
                            <Route path="/drinks-menu" element={
                                <Card className="mb-4">
                                    <Card.Body onClick={handleDrinksMenuClick}>
                                        <DrinksMenu data={drinksData}/>
                                    </Card.Body>
                                </Card>
                            }/>
                            <Route path="/admin/login" element={<Login/>}/>
                            <Route path="/admin/editable-menu" element={
                                <ProtectedRoute user={user}>
                                    <Card className="mb-4">
                                        <Card.Body>
                                            <EditableMenuField/>
                                        </Card.Body>
                                    </Card>
                                </ProtectedRoute>
                            }/>
                            <Route path="/admin/editable-action" element={
                                <ProtectedRoute user={user}>
                                    <Card className="mb-4">
                                        <Card.Body>
                                            <EditableActionsField/>
                                        </Card.Body>
                                    </Card>
                                </ProtectedRoute>
                            }/>
                        </Routes>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </div>
    );
};

export default App;